import { Component } from '@angular/core';

@Component({
  selector: 'ngx-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.scss'],
})
export class TooltipComponent {

}
