import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurgeReportsComponent } from './purge-reports.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbInputModule, NbSelectModule, NbCardModule } from '@nebular/theme';

@NgModule({
  declarations: [PurgeReportsComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NbInputModule, NbSelectModule,
    NbCardModule
  ]
})
export class PurgeReportsModule { }
