<nb-card>
  <nb-card-header>Progress Bar Status</nb-card-header>
  <nb-card-body>
    <nb-progress-bar [value]="20" status="primary">primary</nb-progress-bar>
    <nb-progress-bar [value]="40" status="info">info</nb-progress-bar>
    <nb-progress-bar [value]="60" status="success">success</nb-progress-bar>
    <nb-progress-bar [value]="80" status="warning">warning</nb-progress-bar>
    <nb-progress-bar [value]="100" status="danger">danger</nb-progress-bar>
  </nb-card-body>
</nb-card>

<ngx-interactive-progress-bar></ngx-interactive-progress-bar>

<nb-card>
  <nb-card-header>Progress Bar Size</nb-card-header>
  <nb-card-body>
    <nb-progress-bar [value]="20" size="tiny">tiny</nb-progress-bar>
    <nb-progress-bar [value]="40" size="small">small</nb-progress-bar>
    <nb-progress-bar [value]="60" size="medium">medium (default)</nb-progress-bar>
    <nb-progress-bar [value]="80" size="large">large</nb-progress-bar>
    <nb-progress-bar [value]="100" size="giant">giant</nb-progress-bar>
  </nb-card-body>
</nb-card>

<nb-card>
  <nb-card-header>Progress Bar Value</nb-card-header>
  <nb-card-body>
    <nb-progress-bar [value]="40" status="primary" [displayValue]="true"></nb-progress-bar>
    <nb-progress-bar [value]="60" status="primary">Custom text</nb-progress-bar>
  </nb-card-body>
</nb-card>
