<nb-card [nbSpinner]="true" nbSpinnerSize="tiny" nbSpinnerStatus="primary">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerSize="small" nbSpinnerStatus="primary">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerSize="medium" nbSpinnerStatus="primary">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerSize="large" nbSpinnerStatus="primary">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>
