<div class="row">
  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Colored Alert</nb-card-header>
      <nb-card-body>
        <nb-alert status="primary">You have been successfully authenticated!</nb-alert>
        <nb-alert status="success">You have been successfully authenticated!</nb-alert>
        <nb-alert status="info">You have been successfully authenticated!</nb-alert>
        <nb-alert status="warning">You have been successfully authenticated!</nb-alert>
        <nb-alert status="danger">You have been successfully authenticated!</nb-alert>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Outline Alert</nb-card-header>
      <nb-card-body>
        <nb-alert outline="primary">You have been successfully authenticated!</nb-alert>
        <nb-alert outline="success">You have been successfully authenticated!</nb-alert>
        <nb-alert outline="info">You have been successfully authenticated!</nb-alert>
        <nb-alert outline="warning">You have been successfully authenticated!</nb-alert>
        <nb-alert outline="danger">You have been successfully authenticated!</nb-alert>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Accent Alert</nb-card-header>
      <nb-card-body>
        <nb-alert status="primary" accent="info">You have been successfully authenticated!</nb-alert>
        <nb-alert status="success" accent="danger">You have been successfully authenticated!</nb-alert>
        <nb-alert status="info" accent="warning">You have been successfully authenticated!</nb-alert>
        <nb-alert status="warning" accent="danger">You have been successfully authenticated!</nb-alert>
        <nb-alert status="danger" accent="primary">You have been successfully authenticated!</nb-alert>
      </nb-card-body>
    </nb-card>
  </div>
</div>
