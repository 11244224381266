
<nb-card class="container">
    <nb-card-header class="header">
        <h4>Mettre à jour la liste des destinataires</h4>
    </nb-card-header>
    <nb-card-body>
            <h5> Destinataires de mail de synthèse : </h5>
            <em>Appuyez sur Entrée pour ajouter un destinataire.</em>

            <nb-tag-list (tagRemove)="onTagRemove($event)" class="tag">
              <nb-tag *ngFor="let recipient of recipients" [text]="recipient" removable></nb-tag>
              <input type="text" nbTagInput (tagAdd)="onTagAdd($event)" fullWidth>
            </nb-tag-list>
    </nb-card-body>
    <nb-card-footer>
        <div class="btn-class">
            <button class="cancel" nbButton status="danger" (click)="cancel()">Annuler</button>
            <button class="valider" nbButton status="primary" (click)="submit()">Mettre à jour</button>
        </div>
    </nb-card-footer>
</nb-card>