<div class="row">
  <div class="col-md-12 col-lg-12 col-xxxl-6">
    <ngx-spinner-sizes></ngx-spinner-sizes>
  </div>
  <div class="col-md-12 col-lg-12 col-xxxl-6">
    <ngx-spinner-color></ngx-spinner-color>
  </div>
  <div class="col-md-12 col-lg-12 col-xxxl-6">
    <ngx-spinner-in-buttons></ngx-spinner-in-buttons>
  </div>
  <div class="col-md-12 col-lg-12 col-xxxl-6">
    <ngx-spinner-in-tabs></ngx-spinner-in-tabs>
  </div>
</div>
