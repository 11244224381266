import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: 'showcase-dialog.component.html',
  styleUrls: ['showcase-dialog.component.scss'],
})
export class ShowcaseDialogComponent implements OnInit{

  @Input() title: string;
  @Input() message: string;
  @Input() color: string;

  isPopupOpen: boolean;

  constructor(protected ref: NbDialogRef<ShowcaseDialogComponent>) {}

  ngOnInit(){
    this.isPopupOpen = true;
  }

  dismiss() {
    this.ref.close();
  }
}
