<div class="calendars">
  <div class="calendar-container">
    <span class="subtitle">
      Selected date: {{ date | date }}
    </span>
    <nb-calendar [(date)]="date" [boundingMonth]="true" showWeekNumber></nb-calendar>
  </div>
  <div class="calendar-container">
    <span class="subtitle">
      Selected range: {{ range.start | date }} - {{ range.end | date }}
    </span>
    <nb-calendar-range [(range)]="range"></nb-calendar-range>
  </div>
  <div class="calendar-container">
    <span class="subtitle">
      Selected date: {{ date2 | date }}
    </span>
    <nb-calendar
      [(date)]="date2"
      [dayCellComponent]="dayCellComponent"
    ></nb-calendar>
  </div>
</div>
