import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent implements OnInit {
  selectedFile : File | null = null;
  uploadFileForm = new FormGroup({
    file: new FormControl(null, Validators.required),
  });
  constructor(protected ref: NbDialogRef<FileUploadModalComponent>) { }
  ngOnInit(): void {
  }

  /**
   * A function called when the file is selected
   * @param files 
   */
  onFileChange(files :any) {
    this.selectedFile = files[0]; // retrieve the first selected file
  }

  /**
   * Function called when form is submitted
   */
  submit() {
    if (this.uploadFileForm.valid && this.selectedFile) {
      this.ref.close(this.selectedFile)
    }
  }

  /**
   * Function called when form is canceled
   */
  cancel() {
    this.ref.close();
  }
}
