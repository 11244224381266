<nb-card>
    <nb-card-header class="title">
        <h2 >Connecteurs</h2>
    </nb-card-header>
    <nb-card-body>
        <div class="container-fluid nopadding">
            <div class="row" *ngFor="let connector of connectors">
                <ngx-sub-component [connector]="connector" class="col-12" 
                        (launch)="launch(connector)" 
                        (stop)="stop(connector)" 
                        (updateRecipients)="updateRecipients(connector)"
                ></ngx-sub-component>
            </div>
        </div>
    </nb-card-body>
</nb-card>