import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KeycloakSecurityService } from '../../Keycloak-config/keycloak-security.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient, private KeycloakSecurityService: KeycloakSecurityService) { }

  getConnectorReports(organization_id: string, connector_id: string) {
    try {
      return this.http.get(this.apiUrl + `/organization/${organization_id}/report/connector/${connector_id}`)
    } catch (error) {
      throw new Error(error);
    }
  }

  purgeReports(organization_id: string, connector_id: string, numberOfReportsToKeep: number){
    return this.http.delete(`${this.apiUrl}/organization/${organization_id}/report/connector/${connector_id}/purge?numberOfReportsToKeep=${numberOfReportsToKeep}`);
  }
}
