import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule, NbTagModule, NbInputModule, NbSelectModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateMailModalComponent } from './update-mail-modal.component';

@NgModule({
  declarations: [UpdateMailModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbInputModule,
    NbCardModule,
    NbTagModule,
  ]
})
export class UpdateMailModalModule { }
