<div class="container">
    <nb-card >
        <nb-card-body>
            <div
                class="text-center" 
                [nbSpinner]="true" nbSpinnerSize="small" nbSpinnerStatus="primary">
                {{message}}
            </div>
        </nb-card-body>
    </nb-card>
</div>
