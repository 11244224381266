<div class="container">
    <div *ngIf="connector.is_running" class="container1">
        <div class="load">
            <div class="load__bar load__bar--1"></div>
            <div class="load__bar load__bar--2"></div>
            <div class="load__bar load__bar--3"></div>
            <div class="load__bar load__bar--4"></div>
            <div class="load__bar load__bar--5"></div>
        </div>
    </div>
    <div class="row nomargin">
        <div class="col-sm-12 col-md-5 productName">
            <span [ngClass]="{'started': true, 'stopped': false}">
                <i class="fa fa-circle"></i>
            </span>
            <strong>{{connector.name}}</strong>
        </div>
        <div class="col-sm-12 col-md-7">
            <div class="row">
                <div class="col-sm-5 col-md-7">
                    <span  *ngIf="connector.last_run_datetime" [ngClass]="{'OK':connector.last_run_status, 'erreur': !(connector.last_run_status)}">
                        Dernière exécution : {{connector.last_run_datetime | date:"dd/MM/yy HH:mm"}}
                        <span *ngIf="connector.last_run_status===1 || connector.last_run_status===0">=> {{getstatusString(connector.last_run_status)}}</span>
                    </span>
                </div>
                <div class="col-sm-2 col-md-4">
                    <div class="flex" *ngIf="connector.connector_id == IMPORT_LDAP_ID">
                        <button *ngIf="reports?.length" (click)="showReports()" class="button-reports">Rapports</button>
                        <i (click)="showCsvUsers()" class="fa fa-file-csv pointer iconAnimation" title="Afficher le contenu d'annuaire"></i>
                        <i class="fa fa-eraser pointer iconAnimation" (click)="purgeReports()" aria-hidden="true" title="Nettoyer les rapports"></i>
                        <i class="fa fa-cogs pointer iconAnimationpointer iconAnimation" aria-hidden="true" title="Mettre à jour la liste des destinataires du mail de synthèse" (click)="updateRecipients.emit()"></i>
                        <i class="fas fa-upload pointer iconAnimation" 
                            title="Sélectionner un fichier CSV des utilisateurs à importer" (click)="uploadFile()"
                        ></i>
                    </div>
                </div>
                <div class="col-sm-1 col-md-1">
                    <i class="fa fa-play pointer iconAnimation" (click)="launch.emit()" title="start"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="row description" *ngIf="connector.description">
        {{connector.description}}
    </div>
    <div class="row logs" *ngIf="logs?.length">
        <ngx-log [logs]="logs" class="col-12"></ngx-log>
    </div>
</div>