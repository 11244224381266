<div class="row">
  <div class="col-md-12 col-lg-6 col-xxxl-4">
    <nb-card>
      <nb-card-header>Tooltip With Icon</nb-card-header>
      <nb-card-body>
        <button nbTooltip="This is a tooltip" nbTooltipIcon="home-outline" nbButton>Show Tooltip</button>
        <button nbTooltip="" nbTooltipIcon="alert-triangle" nbTooltipStatus="danger" nbButton>Show Tooltip</button>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-6 col-xxxl-4">
    <nb-card>
      <nb-card-header>Tooltip Placements</nb-card-header>
      <nb-card-body>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="top" nbButton>Top</button>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="right" nbButton>Right</button>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="bottom" nbButton>Bottom</button>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="left" nbButton>Left</button>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-6 col-xxxl-4">
    <nb-card>
      <nb-card-header>Colored Tooltips</nb-card-header>
      <nb-card-body>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="top" nbButton>Default</button>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="top" nbTooltipStatus="primary" nbButton>Primary</button>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="top" nbTooltipStatus="success" nbButton>Success</button>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="top" nbTooltipStatus="danger" nbButton>Danger</button>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="top" nbTooltipStatus="info" nbButton>Info</button>
        <button nbTooltip="This is a tooltip" nbTooltipPlacement="top" nbTooltipStatus="warning" nbButton>Warning</button>
      </nb-card-body>
    </nb-card>
  </div>
</div>
