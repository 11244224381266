import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { NbTagComponent, NbTagInputAddEvent, NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-update-mail-modal',
  templateUrl: './update-mail-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./update-mail-modal.component.scss']
})
export class UpdateMailModalComponent implements OnInit {
  @Input() connector: any;

  /** mails recipients addresses */
  recipients: Set<string>;
  public constructor(protected ref: NbDialogRef<UpdateMailModalComponent>){
  }

  ngOnInit(){
    this.recipients = new Set<string>(this.connector.recipients);
  }

  onTagRemove(tagToRemove: NbTagComponent): void {
    this.recipients.delete(tagToRemove.text);
  }

  onTagAdd({ value, input }: NbTagInputAddEvent): void {
    if (value && this.isValidEmail(value)) {
      this.recipients.add(value);
    }
    input.nativeElement.value = '';
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }


  submit(){
    this.ref.close(this.recipients);
  }

  cancel(){
    this.ref.close();
  }
}
