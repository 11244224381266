<nb-card>
  <nb-card-header>Progress Bar Interactive</nb-card-header>
  <nb-card-body>
    <div class="container">
      <button nbButton appearance="ghost" [disabled]="canDecrease ? null : ''" (click)="decreaseValue()">
        <nb-icon icon="minus" pack="eva"></nb-icon>
      </button>
      <nb-progress-bar [value]="value" [status]="status" [displayValue]="true"></nb-progress-bar>
      <button nbButton appearance="ghost" [disabled]="canIncrease ? null : ''" (click)="increaseValue()">
        <nb-icon icon="plus" pack="eva"></nb-icon>
      </button>
    </div>
  </nb-card-body>
</nb-card>
