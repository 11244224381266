import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {   NbCardModule, NbAccordionModule } from '@nebular/theme';
import { ReportComponent } from './report.component';


@NgModule({
  declarations: [ReportComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbAccordionModule
  ]
})
export class ReportModule { }
