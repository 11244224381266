import { NbDialogRef } from '@nebular/theme';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'ngx-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {
  @Input() connector: any;
  @Input() reports: any;

  public constructor(protected ref: NbDialogRef<ReportComponent>){}

  closeCard(){
    this.ref.close()
  }

}
