<div class="container">
    <h5>{{title}}</h5>
    <form [formGroup]="form" (submit)="submit()">
        <label><strong>Organization : </strong></label>
        <nb-select class="dropdown" formControlName="organization_id" placeholder="Choisissez la liste des organizations à retirer">
            <nb-option *ngFor="let organization of organizations" value="{{organization.organization_id}}"> 
                {{organization.name}}
            </nb-option>
        </nb-select> 

        <div class="btn-class">
            <button class="cancel" nbButton status="danger" (click)="cancel()">Annuler</button>
            <button class="valider" nbButton status="primary" type="submit" [disabled]="form.invalid" [ngClass]="{'disabled': form.invalid}">Valider</button>
        </div>
    </form> 
</div>