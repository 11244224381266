import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-purge-reports',
  templateUrl: './purge-reports.component.html',
  styleUrls: ['./purge-reports.component.scss']
})
export class PurgeReportsComponent {
  form = new FormGroup({
    numberOfReportsToKeep: new FormControl(10, Validators.required),
  });
  public constructor(protected ref: NbDialogRef<PurgeReportsComponent>){
  }

  submit(){
    this.ref.close(this.form.value);
  }

  cancel(){
    this.ref.close();
  }
}
