import { Component, OnInit, Input} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-csv-users-component',
  templateUrl: './csv-users-component.component.html',
  styleUrls: ['./csv-users-component.component.scss']
})
export class CsvUsersComponentComponent implements OnInit {
  @Input() csvFile: any;
  /** a variable to hold the number of lines of csv file */
  numberOfRows: number = 0;

  public constructor(protected ref: NbDialogRef<CsvUsersComponentComponent>){}

  ngOnInit(){
    if (this.csvFile && this.csvFile.users) {
      this.numberOfRows = this.csvFile.users.length;
    }
  }

  closeCard(){
    this.ref.close()
  }

}
