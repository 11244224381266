<nb-card [nbSpinner]="true" nbSpinnerStatus="primary">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerStatus="success">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerStatus="info">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerStatus="warning">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>

<nb-card [nbSpinner]="true" nbSpinnerStatus="danger">
  <nb-card-body>
    Some card content.
  </nb-card-body>
</nb-card>
