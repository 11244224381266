<nb-card class="container">
    <div class="close-icon iconAnimation" (click)="closeCard()">
        <i class="fas fa-times"></i>
    </div>
    <nb-card-header class="header">
        <h2 >Contenu d'annuaire des utilisateurs</h2>
    </nb-card-header>
    <nb-card-body>
        <table class="table table-striped">
            <thead>
              <tr>
                <th *ngFor="let header of csvFile.headers">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of csvFile.users">
                <td *ngFor="let header of csvFile.headers">{{ user[header] }}</td>
              </tr>
            </tbody>
        </table>
    </nb-card-body>
    <nb-card-footer>
        <p class="fileSize"><strong>Nombre de lignes : </strong>{{ numberOfRows }} ligne(s)</p>
        <p class="fileSize"><strong>Taille du fichier : </strong>{{ csvFile.metadata.size }} octets</p>
        <p class="fileDate"><strong>Date de modification : </strong>{{ csvFile.metadata.modifyTime | date:"dd/MM/yy HH:mm:ss" }}</p>
    </nb-card-footer>
</nb-card>