<form [formGroup]="form" (submit)="submit()">
    <nb-card>
        <nb-card-header>
            <h5>Nettoyage des rapports</h5>
        </nb-card-header>
        <nb-card-body>
                <h6> Veuillez choisir le nombre de rapports à garder : </h6>
                <input type="number" nbInput fullWidth formControlName="numberOfReportsToKeep">
        </nb-card-body>
        <nb-card-footer>
            <div class="btn-class">
                <button class="cancel" nbButton status="danger" (click)="cancel()">Annuler</button>
                <button class="valider" nbButton status="primary" (click)="submit()" [disabled]="form.invalid" 
                [ngClass]="{'disabled': form.invalid}">Valider</button>
            </div>
        </nb-card-footer>
    </nb-card>
</form>