<div class="row">
  <div class="col-lg-6">
    <nb-card size="small">
      <nb-card-header>Popover Position</nb-card-header>
      <nb-card-body>
        <p>When popover has not enough space based on the configured placement, it will adjust accordingly trying to fit the screen.</p>
        <button nbButton status="danger" nbPopover="Hello, how are you today?" nbPopoverTrigger="hint" nbPopoverPlacement="left">Left</button>
        <button nbButton status="danger" nbPopover="Hello, how are you today?" nbPopoverTrigger="hint" nbPopoverPlacement="top">Top</button>
        <button nbButton status="danger" nbPopover="Hello, how are you today?" nbPopoverTrigger="hint" nbPopoverPlacement="bottom">Bottom</button>
        <button nbButton status="danger" nbPopover="Hello, how are you today?" nbPopoverTrigger="hint" nbPopoverPlacement="right">Right</button>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-lg-6">
    <nb-card size="small">
      <nb-card-header>Simple Popovers</nb-card-header>
      <nb-card-body>
        <p>In a simples form popover can take a string of text to render.</p>
        <button nbButton status="success" nbPopover="Hello, how are you today?">on click</button>
        <button nbButton status="success" nbPopover="Hello, how are you today?" nbPopoverTrigger="hover">on hover</button>
        <button nbButton status="success" nbPopover="Hello, how are you today?" nbPopoverTrigger="hint">on hint</button>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="row">
  <div class="col-lg-6">

    <ng-template #tabs>
      <nb-tabset>
        <nb-tab tabTitle="What's up?">
          <div class="p-4">
            Such a wonderful day!
          </div>
        </nb-tab>
        <nb-tab tabTitle="Second Tab">
          <div class="p-4">
            Indeed!
          </div>
        </nb-tab>
      </nb-tabset>
    </ng-template>

    <ng-template #form>
      <div class="p-4">
        <form>
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Recipients">
          </div>
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Subject">
          </div>
          <div class="form-group">
            <textarea class="form-control" placeholder="Message"></textarea>
          </div>
          <button type="submit" class="btn btn-primary w-100">Send</button>
        </form>
      </div>
    </ng-template>

    <ng-template #card>
      <nb-card class="popover-card">
        <nb-card-header status="warning">
          Hello!
        </nb-card-header>
        <nb-card-body>
          Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.
          Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.
        </nb-card-body>
      </nb-card>
    </ng-template>

    <nb-card size="small">
      <nb-card-header>Template Popovers</nb-card-header>
      <nb-card-body>
        <p>You can pass a refference to `ng-template` to be rendered.</p>
        <button nbButton status="warning" [nbPopover]="tabs">With tabs</button>
        <button nbButton status="warning" [nbPopover]="form">With form</button>
        <button nbButton status="warning" [nbPopover]="card">With card</button>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-lg-6">
    <nb-card size="small">
      <nb-card-header>Component Popovers</nb-card-header>
      <nb-card-body>
        <p>Same way popover can render any angular compnoent.</p>
        <button nbButton status="warning" [nbPopover]="tabsComponent">With tabs</button>
        <button nbButton status="warning" [nbPopover]="formComponent">With form</button>
        <button nbButton status="warning" [nbPopover]="cardComponent">With card</button>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-header>Event Debouncing</nb-card-header>
      <nb-card-body class="debounce-card">
        <p>Quickly move mouse cursor over the buttons, only the last popover will be created. It allows us to avoid excess white improving page performance.</p>

        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
        <button nbButton nbPopover="Popover!" nbPopoverTrigger="hint">
          show hint
        </button>
      </nb-card-body>
    </nb-card>
  </div>
</div>
