<div class="row">
  <div class="col-md-12 col-lg-12 col-xxxl-6">
    <nb-card>
      <nb-card-header>Window Form</nb-card-header>
      <nb-card-body>
        <button (click)="openWindowForm()" nbButton>Open window form</button>

        <button (click)="openWindow(contentTemplate)" nbButton>Open window with template</button>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-12 col-xxxl-6">
    <nb-card>
      <nb-card-header>Window Without Backdrop</nb-card-header>
      <nb-card-body>
        <button (click)="openWindow(contentTemplate)" nbButton>Open window with backdrop</button>

        <button (click)="openWindowWithoutBackdrop()" nbButton>Open window without backdrop</button>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<ng-template #contentTemplate let-data>
  <p>Here is the text provided via config: "{{ data.text }}"</p>
</ng-template>

<ng-template #disabledEsc>
  Disabled close on escape click.
</ng-template>
