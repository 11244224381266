export const environment = {
    API_URL: 'https://admin.planb.grandlyon.com/api',
    KC_URL: 'https://auth.admin.planb.grandlyon.com/',
    KC_REALM: 'master',
    KC_CLIENT_ID: 'phenix-frontend',
    DIR: '/usr/src/app',
    NEXTCLOUD_URL: 'https://drive.planb.grandlyon.com/',
    SITE_INTERNE_URL: 'https://planb.grandlyon.com/',
    SITE_EXTERNE_URL: 'https://ext.planb.grandlyon.com/',
    KEYCLOAK_URL: 'https://auth.planb.grandlyon.com/admin/grandlyon/console',
    MAILSERVER_URL: 'https://mail.planb.grandlyon.com/',
    CHAT_URL: 'https://chat.planb.grandlyon.com/',
   production: 'true',
   ORGANIZATION_ID: 'grandlyon',
   PROD_TAG_ID: 'prod_1690553289436',
   IMPORT_LDAP_ID:'import ldap',
   ALERT_USER_ID:'alert user',
};
