<nb-card>
  <nb-card-header>
    <p><code>NbCalendarKitModule</code> is a module that contains multiple useful components for building custom calendars.
      So if you think our calendars is not enough powerful for you just use calendar-kit and build your own calendar!</p>
  </nb-card-header>
  <nb-card-body>
    <nb-calendar-month-picker
      [(month)]="month"
      [cellComponent]="monthCellComponent"
    ></nb-calendar-month-picker>
  </nb-card-body>
</nb-card>
