<nb-card class="container">
    <div class="close-icon iconAnimation" (click)="closeCard()">
        <i class="fas fa-times"></i>
    </div>
    <nb-card-header class="header">
        <h2 >Rapports du connecteur {{connector.name}}</h2>
    </nb-card-header>
    <nb-card-body>
        <nb-accordion *ngFor="let report of reports" class="accordion">
            <nb-accordion-item #item>
              <nb-accordion-item-header [ngClass]="{'success': report.status, 'error': !report.status}">
                <strong>Rapport du {{report.executionEndDate}}
                    <i class="fas fa-hand-pointer" *ngIf="report.importType=='manuel'" title="Import manuel"></i>
                    <i class="fas fa-stopwatch" *ngIf="report.importType=='automatique'" title="Import automatique"></i></strong>
              </nb-accordion-item-header>
              <!-- Success part -->
              <nb-accordion-item-body class="accordion-body" *ngIf="report.status">
                <p>Import {{report.importType}}</p>
                <p *ngIf="report.executionStartDate">Début d'exécution : {{report.executionStartDate}}</p>
                <p *ngIf="report.executionEndDate">Fin d'exécution : {{report.executionEndDate}}</p>
                <div *ngIf="report.services?.length">Services concernés par l'import : <br>
                    <div *ngFor="let service of report.services">
                        <p *ngIf="service.snapshotDateTime && service.generatedSnapshotDateTime">
                            La version de données du service {{service.service_id}} a été créée le  {{service.generatedSnapshotDateTime}} ({{service.addedTagName}}) depuis la version de données du {{service.snapshotDateTime}}.
                        </p>
                    </div>
                </div>
                <div>
                    <p>Détail :</p>
                    <!-- """"""""""""""""""""""""""""""""""""""""""""
                            invalid users from csv part
                    """""""""""""""""""""""""""""""""""""""""""" -->
                    <div *ngIf="report.failedUsersErrors?.length">
                        <span>Utilisateurs Invalides :</span><br>
                        {{report.failedUsersErrors?.length}} Utilisateur(s) en erreur <i  class="fas fa-times nonImported"></i> :
                        <ul>
                            <li>ligne;identifiant;erreur</li>
                            <li *ngFor="let failedUserError of report.failedUsersErrors">
                                {{failedUserError}}
                            </li>
                        </ul>
                    </div>
                    <!-- """"""""""""""""""""""""""""""""""""""""""""
                            service authentication part
                    """""""""""""""""""""""""""""""""""""""""""" -->
                    <span *ngIf="report.importedUsers">Pour le service authentification : </span><br>
                    <div *ngIf="report.importedUsers">
                        {{report.importedUsers}} Utilisateur(s) importé(s) <i  class="fas fa-check imported"></i>
                    </div>
                    <div *ngIf="report.failedUsersToAuthErrors.length">
                        {{report.failedUsersToAuthErrors.length}} Utilisateur(s) échoué(s): <i  class="fas fa-times nonImported"></i>
                        <ul>
                            <li *ngFor="let failedUserToAuthError of report.failedUsersToAuthErrors">
                                {{failedUserToAuthError}}
                            </li>
                        </ul>
                    </div>
                    <!-- """"""""""""""""""""""""""""""""""""""""""""
                            service mail part
                    """""""""""""""""""""""""""""""""""""""""""" -->
                    <p *ngIf="report.importedUsersToMail?.length || 
                        report.existingUsersInMail?.length || 
                        report.deletedUsersFromMail?.length || 
                        report.failedUsersToMail?.length"><br><br> Pour le service mail : </p>
                    <div *ngIf="report.importedUsersToMail?.length">
                        {{report.importedUsersToMail.length}} Utilisateur(s) importé(s) <i  class="fas fa-check imported"></i>
                    </div>
                    <div *ngIf="report.existingUsersInMail?.length">
                        {{report.existingUsersInMail.length}} Utilisateur(s) déjà enregistré(s) <i  class="fas fa-check imported"></i>
                    </div>
                    <div *ngIf="report.deletedUsersFromMail?.length">
                        {{report.deletedUsersFromMail.length}} Utilisateur(s) supprimé(s) <i  class="fas fa-trash nonImported"></i>
                    </div>
                    <div *ngIf="report.failedUsersToMail?.length || report.deletedUsersFromMail?.length">
                        {{report.failedUsersToMail.length}} Utilisateur(s) échoué(s) <i  class="fas fa-times nonImported"></i>
                        <p>Vous trouverez dans le tableau suivant un résumé d'import des utilisateurs dans le service mail :</p>
                        <div>
                            <table class="table table-bordered">
                                <thead class="thead-dark">
                                    <th *ngIf="report.deletedUsersFromMail?.length">Utilisateurs supprimés</th>
                                    <th *ngIf="report.failedUsersToMail?.length">Utilisateurs échoués</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td *ngIf="report.deletedUsersFromMail?.length">
                                            <ul>
                                                <li *ngFor="let deletedUserFromMail of report.deletedUsersFromMail">{{deletedUserFromMail}}</li>
                                            </ul>
                                        </td>
                                        <td *ngIf="report.failedUsersToMail?.length">
                                            <ul>
                                                <li *ngFor="let failedUserToMail of report.failedUsersToMail">{{failedUserToMail}}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="report.mailSentResult">
                  <span>Envoi de mail aux administrateurs : </span>
                  <span>{{report.mailSentResult}}</span><br><br>
                </div>
              </nb-accordion-item-body>
              <!-- Errors part -->
              <nb-accordion-item-body class="accordion-body" *ngIf="!report.status">
                <p>Import {{report.importType}}</p>
                <p *ngIf="report.executionStartDate">Début d'exécution : {{report.executionStartDate}}</p>
                <p *ngIf="report.executionEndDate">Fin d'exécution : {{report.executionEndDate}}</p>
                <div *ngIf="report.services?.length">Services concernés par l'import : <br>
                    <div *ngFor="let service of report.services">
                        <p *ngIf="service.snapshotDateTime">
                            L'import a été exécuté sur la Sauvegarde du {{service.snapshotDateTime}} du service {{service.service_id}}, mais n'a pas été sauvegardé.
                        </p>
                    </div>
                </div>
                <div *ngIf="report.mailSentResult">
                  <span>Envoi de mail aux administrateurs : </span>
                  <span>{{report.mailSentResult}}</span><br><br>
                </div>
            <!-- """"""""""""""""""""""""""""""""""""""""""""
                        invalid users from csv part
                """""""""""""""""""""""""""""""""""""""""""" -->
                <div *ngIf="report.failedUsersErrors?.length">
                    <span>Utilisateurs Invalides :</span><br>
                    {{report.failedUsersErrors?.length}} Utilisateur(s) en erreur <i  class="fas fa-times nonImported"></i> :
                    <ul>
                        <li>ligne;identifiant;erreur</li>
                        <li *ngFor="let failedUserError of report.failedUsersErrors">
                            {{failedUserError}}
                        </li>
                    </ul>
                </div>
                <!-- """"""""""""""""""""""""""""""""""""""""""""
                        service authentication part
                """""""""""""""""""""""""""""""""""""""""""" -->
                <div *ngIf="report.failedUsersToAuthErrors.length">
                    <span>Pour le service authentification : </span><br>
                    {{report.failedUsersToAuthErrors.length}} Utilisateur(s) échoué(s): <i  class="fas fa-times nonImported"></i>
                    <ul>
                        <li *ngFor="let failedUserToAuthError of report.failedUsersToAuthErrors">
                            {{failedUserToAuthError}}
                        </li>
                    </ul>
                </div>
                <!-- """"""""""""""""""""""""""""""""""""""""""""
                        service mail part
                """""""""""""""""""""""""""""""""""""""""""" -->
                <p *ngIf="report.failedUsersToMail?.length"><br><br> Pour le service mail : </p>
                <div *ngIf="report.failedUsersToMail?.length">
                    {{report.failedUsersToMail.length}} Utilisateur(s) en erreur <i  class="fas fa-times nonImported"></i>
                    <p>Vous trouverez dans le tableau suivant un résumé d'import des utilisateurs dans le service mail :</p>
                    <div>
                        <table class="table table-bordered">
                            <thead class="thead-dark">
                                <th *ngIf="report.failedUsersToMail?.length">Utilisateurs échoué(s)</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td *ngIf="report.failedUsersToMail?.length">
                                        <ul>
                                            <li *ngFor="let failedUserToMail of report.failedUsersToMail">{{failedUserToMail}}</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- """"""""""""""""""""""""""""""""""""""""""""
                        Main error
                """""""""""""""""""""""""""""""""""""""""""" -->
                <div *ngIf="report.errors?.length">Erreurs
                    <ul>
                        <li *ngFor="let error of report.errors">{{error}}</li>
                    </ul>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
    </nb-card-body>
    <nb-card-footer>
    </nb-card-footer>
</nb-card>
