import { Component } from '@angular/core';

@Component({
  selector: 'ngx-progress-bar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['progress-bar.component.scss'],
})
export class ProgressBarComponent {

}
