import { Component } from '@angular/core';

@Component({
  selector: 'ngx-nebular-form-inputs',
  templateUrl: 'nebular-form-inputs.component.html',
  styleUrls: ['nebular-form-inputs.component.scss'],
})
export class NebularFormInputsComponent {

}
