<nb-card>
  <nb-card-header>Enter your name</nb-card-header>
  <nb-card-body>
    <input #name nbInput placeholder="Name">
  </nb-card-body>
  <nb-card-footer>
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancel</button>
    <button nbButton status="success" (click)="submit(name.value)">Submit</button>
  </nb-card-footer>
</nb-card>
